
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient } from '../types';

// this.OrtoToothHeight = new Number(value / 0.75).whole(100, '');
// this.OrtoToothWidth1 = new Number(value * 1.6).whole(100, '');
// this.OrtoToothWidth2 = value;
// this.OrtoToothWidth3 = new Number(value * 0.6).whole(100, '');

@Component
export default class CustomerOrtoTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;
}
